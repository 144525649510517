import headerGif from "./images/header-gif.gif";
import headerBabyMurabak from "./images/header-baby-murabak.png";
import diamondLeft from "./images/diamond-left.png";
import diamondRight from "./images/diamond-right.png";
import aboutGif from "./images/about-gif.gif";
import tokenomics from "./images/tokenomics.png";
import footer from "./images/footer.png";

import "./App.css";
import { useState } from "react";

function App() {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("0x87DF93b4217607b06c60e7D1b966F7ED325c3d4E");
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="App">
      <header className="container mx-auto px-2 text-center py-8">
        <h1 className="text-title text-5xl h-20 lg:text-8xl lg:h-28">
          Baby Mubarak
        </h1>
        <h4 className="text-2xl lg:text-3xl mt-3">
          “Changing the Game, One Meme at a Time!”
        </h4>

        <div className="mt-16">
          <div className="relative mx-auto max-w-[1000px] w-full border-[12px] border-solid border-[#FDCF4A]">
            <img className="w-full" src={headerGif} alt="header-gif" />

            <div className="absolute left-1/2 -translate-x-1/2 bottom-0 lg:-bottom-16">
              <img
                className="logo-animation w-[140px] lg:w-[400px] "
                src={headerBabyMurabak}
                alt=""
              />
            </div>

            <img
              className="absolute w-[90px] -left-16 top-1/2 -translate-y-1/2"
              src={diamondLeft}
              alt=""
            />
            <img
              className="absolute w-[150px] -right-24 top-1/2 -translate-y-1/2"
              src={diamondRight}
              alt=""
            />
          </div>
        </div>

        <div className="mt-12 lg:mt-20 flex items-center justify-center gap-2 lg:gap-6">
          <a
            href="https://t.me/BabyMubarakChat"
            target="_blank"
            rel="noreferrer noopener"
            className="p-2 lg:p-3 bg-primary rounded-full border-2 border-solid border-[#381F31]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <mask
                id="mask0_2_47"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="52"
                height="52"
              >
                <rect width="52" height="52" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2_47)">
                <path
                  d="M8.89099 23.9038L32.122 14.2573C34.4152 13.2524 42.1921 10.0369 42.1921 10.0369C42.1921 10.0369 45.7814 8.63013 45.4823 12.0466C45.3826 13.4534 44.585 18.3772 43.7873 23.7029L41.2947 39.479C41.2947 39.479 41.0953 41.7902 39.4004 42.1921C37.7054 42.5941 34.9137 40.7853 34.4152 40.3834C34.0164 40.0819 26.9374 35.5601 24.3451 33.3494C23.6472 32.7465 22.8495 31.5407 24.4448 30.1339C28.0341 26.8179 32.3214 22.698 34.9137 20.0854C36.1101 18.8796 37.3066 16.066 32.3214 19.4825L18.2632 29.0286C18.2632 29.0286 16.6679 30.0334 13.6768 29.1291C10.6857 28.2247 7.19603 27.0189 7.19603 27.0189C7.19603 27.0189 4.80313 25.5116 8.89099 23.9038Z"
                  fill="#381F31"
                />
              </g>
            </svg>
          </a>

          <a
            href="https://x.com/BabyMubarakX"
            target="_blank"
            rel="noreferrer noopener"
            className="p-2 lg:p-3 bg-primary rounded-full border-2 border-solid border-[#381F31]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <mask
                id="mask0_2_55"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="52"
                height="52"
              >
                <rect width="52" height="52" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2_55)">
                <path
                  d="M6.64093 7.24683L21.6806 27.1807L6.54596 43.3878H9.95217L23.2025 29.1982L33.9085 43.3878H45.4999L29.614 22.3327L43.7013 7.24683H40.2951L28.0921 20.3151L18.2324 7.24683H6.64093ZM11.65 9.73393H16.9752L40.4901 40.9003H35.1649L11.65 9.73393Z"
                  fill="#381F31"
                />
              </g>
            </svg>
          </a>

          <a
            href="https://mevx.io/bsc/0x87DF93b4217607b06c60e7D1b966F7ED325c3d4E"
            target="_blank"
            rel="noreferrer noopener"
            className="bg-primary px-6 py-4 text-xl lg:text-3xl uppercase rounded-xl border-2 border-solid border-[#381F31]"
          >
            Buy Now
          </a>
        </div>
      </header>

      <main>
        <section className="container mx-auto px-2 text-center py-12 lg:py-24">
          <h2 className="text-title text-5xl lg:text-8xl lg:h-28">About</h2>

          <div className="mt-16">
            <div className="relative mx-auto max-w-[520px] w-full border-[12px] border-solid border-[#FDCF4A]">
              <img className="w-full" src={aboutGif} alt="header-gif" />

              <img
                className="absolute w-[150px] -left-48 top-24"
                src={diamondLeft}
                alt=""
              />
              <img
                className="absolute w-[80px] -right-32 bottom-12"
                src={diamondRight}
                alt=""
              />
            </div>
          </div>

          <p className="max-w-[800px] mx-auto text-xl lg:text-2xl mt-10">
            "CZ just dropped a hint that he’s behind Baby Mubarak – classic
            cryptic CZ move! If you've followed him long enough, you know when
            he shills like this, a Binance listing is almost inevitable. The
            Arab world, with their deep pockets, is primed to send Baby Mubarak
            to a $1 billion market cap. The community has officially taken
            control, with the CTO all-in on pushing it to the moon. Get ready
            for Baby Mubarak to explode!"
          </p>

          <div className="mt-10 flex items-center justify-center gap-3 max-w-[800px] mx-auto bg-primary px-6 py-4 text-xl lg:text-2xl uppercase rounded-xl border-2 border-solid border-[#381F31]">
            <span className="ca">
              0x87df93b4217607b06c60e7d1b966f7ed325c3d4e
            </span>
            <button onClick={handleCopy}>
              {copied ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <mask
                    id="mask0_25_31"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <rect width="32" height="32" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_25_31)">
                    <path
                      d="M12.7 24.0001L26.8333 9.86675L24.9523 8.00008L12.6856 20.2524L7.01429 14.5667L5.13329 16.4477L12.7 24.0001ZM12.7 28.9914L0.175293 16.4667L7.03329 9.59408L12.7 15.2607L24.9376 2.98975L31.858 9.83341L12.7 28.9914Z"
                      fill="#381F31"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <mask
                    id="mask0_2_22"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="32"
                    height="32"
                  >
                    <rect width="32" height="32" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2_22)">
                    <path
                      d="M12 24.0001C11.2667 24.0001 10.6389 23.739 10.1167 23.2167C9.59444 22.6945 9.33333 22.0667 9.33333 21.3334V5.33341C9.33333 4.60008 9.59444 3.9723 10.1167 3.45008C10.6389 2.92786 11.2667 2.66675 12 2.66675H24C24.7333 2.66675 25.3611 2.92786 25.8833 3.45008C26.4056 3.9723 26.6667 4.60008 26.6667 5.33341V21.3334C26.6667 22.0667 26.4056 22.6945 25.8833 23.2167C25.3611 23.739 24.7333 24.0001 24 24.0001H12ZM6.66667 29.3334C5.93333 29.3334 5.30556 29.0723 4.78333 28.5501C4.26111 28.0279 4 27.4001 4 26.6667V8.00008H6.66667V26.6667H21.3333V29.3334H6.66667Z"
                      fill="#381F31"
                    />
                  </g>
                </svg>
              )}
            </button>
          </div>
        </section>

        <section className="container mx-auto px-2 py-12">
          <div className="max-w-[1000px] mx-auto flex flex-col gap-8 items-center justify-center">
            <div className="w-full">
              <div id="dexscreener-embed">
                <iframe
                  title="dexscreener-babymubrak"
                  src="https://dexscreener.com/bsc/0xec5D7e55F387d416DF295F0C48dA397701c66366?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15"
                ></iframe>
              </div>
            </div>

            <div className="max-w-[669px]">
              <img className="w-full" src={tokenomics} alt="" />
            </div>

            {/* <div className="flex flex-col gap-6">
              <div className="bg-primary p-6 rounded-xl border-2 border-solid border-[#381F31] flex flex-col gap-2 justify-center items-center text-center">
                <span className="text-2xl">Total Supply</span>
                <h3 className="text-3xl">Coming soon</h3>
              </div>
              <div className="bg-primary p-6 rounded-xl border-2 border-solid border-[#381F31] flex flex-col gap-2 justify-center items-center text-center">
                <span className="text-2xl">Buy/Sell Tax</span>
                <h3 className="text-3xl">Coming soon</h3>
              </div>
              <div className="bg-primary p-6 rounded-xl border-2 border-solid border-[#381F31] flex flex-col gap-2 justify-center items-center text-center">
                <span className="text-2xl">Burned</span>
                <h3 className="text-3xl">Coming soon</h3>
              </div>
            </div> */}
          </div>
        </section>

        <section className="container mx-auto px-2 text-center py-8">
          <h2 className="text-title text-5xl lg:text-8xl h-28">
            Join Our Community
          </h2>

          <h4 className="max-w-[800px] mx-auto text-xl lg:text-3xl mt-3">
            Baby Mubarak – a crypto meme on BNB Chain, now fully taken over and
            driven by the community!
          </h4>

          <div className="mt-12 flex items-center justify-center gap-2 lg:gap-6">
            <a
              href="https://t.me/BabyMubarakChat"
              target="_blank"
              rel="noreferrer noopener"
              className="p-2 lg:p-3 bg-primary rounded-full border-2 border-solid border-[#381F31]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
              >
                <mask
                  id="mask0_2_47"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="52"
                  height="52"
                >
                  <rect width="52" height="52" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2_47)">
                  <path
                    d="M8.89099 23.9038L32.122 14.2573C34.4152 13.2524 42.1921 10.0369 42.1921 10.0369C42.1921 10.0369 45.7814 8.63013 45.4823 12.0466C45.3826 13.4534 44.585 18.3772 43.7873 23.7029L41.2947 39.479C41.2947 39.479 41.0953 41.7902 39.4004 42.1921C37.7054 42.5941 34.9137 40.7853 34.4152 40.3834C34.0164 40.0819 26.9374 35.5601 24.3451 33.3494C23.6472 32.7465 22.8495 31.5407 24.4448 30.1339C28.0341 26.8179 32.3214 22.698 34.9137 20.0854C36.1101 18.8796 37.3066 16.066 32.3214 19.4825L18.2632 29.0286C18.2632 29.0286 16.6679 30.0334 13.6768 29.1291C10.6857 28.2247 7.19603 27.0189 7.19603 27.0189C7.19603 27.0189 4.80313 25.5116 8.89099 23.9038Z"
                    fill="#381F31"
                  />
                </g>
              </svg>
            </a>

            <a
              href="https://x.com/BabyMubarakX"
              target="_blank"
              rel="noreferrer noopener"
              className="p-2 lg:p-3 bg-primary rounded-full border-2 border-solid border-[#381F31]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
              >
                <mask
                  id="mask0_2_55"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="52"
                  height="52"
                >
                  <rect width="52" height="52" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2_55)">
                  <path
                    d="M6.64093 7.24683L21.6806 27.1807L6.54596 43.3878H9.95217L23.2025 29.1982L33.9085 43.3878H45.4999L29.614 22.3327L43.7013 7.24683H40.2951L28.0921 20.3151L18.2324 7.24683H6.64093ZM11.65 9.73393H16.9752L40.4901 40.9003H35.1649L11.65 9.73393Z"
                    fill="#381F31"
                  />
                </g>
              </svg>
            </a>

            <a
              href="https://mevx.io/bsc/0x87DF93b4217607b06c60e7D1b966F7ED325c3d4E"
              target="_blank"
              rel="noreferrer noopener"
              className="bg-primary px-6 py-4 text-xl lg:text-3xl uppercase rounded-xl border-2 border-solid border-[#381F31]"
            >
              Buy Now
            </a>
          </div>
        </section>
      </main>

      <footer className="pt-16 lg:pt-32 text-center">
        <span className="lg:text-xl">
          @2025 Baby Mubarak. ALL RIGHTS RESERVED
        </span>
        <div className="w-full mt-10">
          <img className="w-full" src={footer} alt="" />
        </div>
      </footer>
    </div>
  );
}

export default App;
